/* Base styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

.Hbody {
  margin: 0;
  padding: 0 10px;
  width: 100%;
}

.heading-section {
  margin: 50px auto;
  width: 100%; /* Full width */
  padding: 0 10px; /* Small padding */
}

.heading-section h1 {
  color: orangered;
  font-size: 2rem;
  text-align: center;
}

.hero-container {
  height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.hero-container > h1 {
  color: #000;
  font-size: 50px;
  margin-top: -100px;
}

.search {
  width: 40%; /* Adjusted width for better responsiveness */
  margin-top: 20px;
  margin-left: auto;  /* Center the search bar horizontally */
  margin-right: auto; /* Center the search bar horizontally */
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 30px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out; 
}

.search:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
}

.search input {
  width: 85%; /* Adjust input width */
  border: none;
  font-size: 1.1rem;
  padding: 0.8rem;
  outline: none;
  color: #333;
  border-radius: 25px; /* Rounded input corners */
  transition: all 0.3s ease-in-out;
}

.search input:focus {
  border: 2px solid green;
}

.search i {
  font-size: 1.8rem;
  color: green; 
  cursor: pointer;
  margin-left: 10px; /* Space between input and icon */
}

#slider, #slide {
  width: 100vw; /* Full viewport width */
  overflow-x: scroll; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of child elements */
  scroll-snap-type: x mandatory; /* Enable snapping for better UX */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  padding: 0; /* Remove any extra padding */
  margin: 0; /* Remove extra margins */
}
#slider h2, #slide h3{
  position: absolute;
}

.slider-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}

.slider-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 960px) {
  .heading-section h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .search {
    width: 70%; /* Adjust width for medium screens */
  }
}

@media screen and (max-width: 768px) {
  .search {
    width: 80%; /* Adjust width for smaller screens */
    margin-top: 15px;
  }

  .heading-section h1 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .search {
    width: 90%;
    margin: 20px auto;
  }

  .Hbody {
    padding: 0;
    margin: 0;
  }

  .heading-section {
    padding: 0 10px; /* Consistent padding */
  }

  #slider, #slide {
    width: 800vw; /* Ensure full width on smaller screens */
    padding: 0;
    margin: 0; /* Remove any default margin/padding */
  }
}
