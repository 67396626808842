/* Global Styles */
* {
  box-sizing: border-box;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

html, body {
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  margin-top: 0; /* Ensure there’s no margin at the top */
}

/* Artist Section */
.pic {
  margin-top: 0; /* Ensure no extra space above the image */
  text-align: center;
  position: relative;
  top: 0;
  z-index: 10; /* Keep image above the content */
}

.martha {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s ease-in-out;
  margin-top: 20px; /* Adjust the spacing around the image */
}

.martha:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 128, 0, 0.4);
}

/* Container and Title */
.container {
  margin: 20px auto;
  text-align: center;
  padding: 20px;
  padding-top: 20px; 
  flex: 1; 
}

.container h1 {
  font-size: 3rem;
  color: green;
}

/* List Styles */
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0;
}

.list-items {
  width: 90%;
  max-width: 500px;
  background-color: #f8f8f8;
  border: 2px solid green;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s ease-in-out;
}

.list-items:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 128, 0, 0.2);
}

/* Audio Player */
.play {
  width: 100%;
  margin-bottom: 10px;
  outline: none;
}

/* Download Button */
.dowload-btn {
  height: 40px;
  width: 100px;
  margin-left: 15px;
}

.dowload-btn img {
  width: 100%;
  height: auto;
  max-height: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 128, 0, 0.4);
  transition: transform 0.3s, box-shadow 0.3s ease-in-out;
}

.dowload-btn img:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(255, 95, 31, 0.6);
}

/* Links */
.list-items a {
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
  margin-top: 10px;
  transition: color 0.3s;
}

.list-items a:hover {
  color: green;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .martha {
    width: 180px;
    height: 180px;
  }

  .container h1 {
    font-size: 2rem;
  }

  .list-items {
    width: 95%;
    padding: 15px;
  }

  .list-items a {
    font-size: 1rem;
  }

  .dowload-btn {
    height: 35px;
    width: 90px;
  }

  .play {
    width: 100%;
  }
}
