*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
.room{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 300px;
  margin-top: 50px;
  text-align: left;
  width: 650px;
  height: 400px;
  padding-left: 200px;
  box-sizing: border-box;
  background: url("/public/images/skizalogo.png") no-repeat;
}
.form{
  margin: 5px;
}
.label{
  color:green;
}
.input{
  width: 300px;
  height: 30px;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
}
.descriptionInput{
  width: 300px;
  height: 100%;
  border: 1px solid #000;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}
.submit{
  width: 100%;
  height: 30px;
  border: 1px solid #000;
  border-radius: 5px;
  padding-left: 130px;
  padding-right: 130px;
  margin: 5px;
  background: green;
  color:#fff;
  font-size: 1rem;
}
.submit:hover{
  background: #fff;
  color: green;
  transition: 250ms;
  transition: all 0.3s ease-out;
}
@media screen and (max-width: 960px){
  .room{
    margin-left: 70px;
    width: 300px;
    height: 400px;
    background: none;
    margin-bottom: 100px;
  }
  .input{
    width: 250px;
  }
  .descriptionInput{
    width: 250px;
  }
  .submit{
    width: 250px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .form{
    margin-right: 250px;
    padding-top: 100px;
  }
}