/* Ensure the content takes up the remaining space */
.main-content {
  flex-grow: 1;
}

.footer-container {
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white; 
  margin-top: auto; /* This ensures the footer is pushed to the bottom */
}

/* Footer styles remain unchanged */
.social-media {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.social-icons {
  margin-top: 20px;
  width: 280px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 0;
}

.social-icons p {
  color: green;
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.social-icons ul {
  color: green;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  list-style: none;
  padding-left: 0;
}

.social-icons li {
  font-size: 1rem;
  margin-bottom: 5px;
}

.mail a {
  color: green;
  text-decoration: none;
}

.mail a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media screen and (max-width: 820px) {
  .footer-container {
    align-items: center;
    padding: 2rem 0;
  }

  .social-media {
    align-items: center;
    width: 100%;
  }

  .social-icons {
    margin: 0;
    margin-top: 10px;
    width: 100%;
    padding: 0 20px;
  }

  .social-icons p {
    font-size: 1.2rem;
    text-align: center;
  }

  .social-icons ul {
    padding-left: 0;
    text-align: center;
  }

  .social-icons li {
    font-size: 1rem;
  }

  .mail a {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .footer-container {
    padding: 2rem 0;
  }

  .social-media-wrap {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .social-icons {
    margin-top: 10px;
    width: auto;
  }

  .social-icons p {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .social-icons ul {
    font-size: 1rem;
  }

  .mail a {
    font-size: 1rem;
  }
}
